import classNames from 'classnames';
import type React from 'react';

type PointerEventsWrapperProps = {
  children: React.ReactNode;
  className?: string;
  allowedClick?: boolean;
};

function PointerEventsWrapper({
  children = null,
  className = null,
  allowedClick = false,
  ...props
}: PointerEventsWrapperProps): React.JSX.Element {
  if (!children) return null;

  return (
    <div className={classNames('p-relative', className)} {...props}>
      <div
        style={{
          position: allowedClick ? 'relative' : 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          cursor: allowedClick ? 'initial' : 'not-allowed',
          zIndex: 2,
        }}
      />
      {children}
    </div>
  );
}

export default PointerEventsWrapper;
